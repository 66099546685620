import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// const Maintainance = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         widht: "100vw",
//         height: "100vh",
//         justifyContent: "center",
//         alignItems: "center",
//         fontSize: "3rem",
//         textTransform: "uppercase",
//         color: "white",
//         background: "#181a1c",
//       }}
//     >
//       Under Maintainance
//     </div>
//   );
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
