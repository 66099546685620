import {
  AppBar,
  Dialog,
  IconButton,
  Button as Mbutton,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { ExportTableButton } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, CustomTable } from "../../components";
import {
  Close,
  DeleteOutline,
  DeleteOutlined,
  Save,
} from "@mui/icons-material";
import { TabIcon } from "../../assets";
import styles from "./ManageArtists.module.scss";
import { CampaignContext, CurrentContext } from "../../utils/contexts";
import { tableData } from "../../utils/constants";
import { selectArtistColumns } from "../../utils/constants/tableData";
import { MainLayout } from "../../layouts";
import { getDBArtistObj, showAlert } from "../../utils";
import { API_ARTIST } from "../../utils/API";
import { Popconfirm } from "antd";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageArtists = () => {
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([]);
  const [modifiedArtists, setModifiedArtists] = useState({});
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);

  const { setTabIndex } = useContext(CurrentContext);
  const { artists } = useContext(CampaignContext);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTabsChange = (event, newValue) => {
    setTabIndex(newValue);
    setTab(newValue);
  };

  const { campaign } = useContext(CurrentContext);

  function handleSelectRow(rows) {
    // setCampaign({ ...campaign, selectedArtists: rows });
    // setSelectedArtists(rows);
    setSelectedRows(rows);
  }

  const { updateArtistsGlobal } = useContext(CampaignContext);

  async function handleSave() {
    if (!Object.values(modifiedArtists).length) {
      showAlert("warning", "No changes made");
      return;
    }
    console.log(modifiedArtists);
    let res = await updateArtistsGlobal(
      Object.values(modifiedArtists).map((item) => getDBArtistObj(item))
    );
    console.log("Updated Global", res);
    showAlert("success", "Updated Artist(s) Successfully");
  }

  useEffect(() => {
    // if (campaign) {
    // const selected = campaign?.selectedArtists?.map((artist) => artist._id);
    if (artists?.length) {
      let tCategories = new Set();
      let tLanguages = new Set();
      let tArtists = [];
      artists.forEach((artist) => {
        tArtists.push({
          ...artist,
          key: artist._id,
          instagramLink: artist.instagram?.link || "NA",
          youtubeLink: artist.youtube?.link || "NA",
          ytSubscribers: artist.youtube?.subscribers || "NA",
          igFollowers: artist.instagram?.followers || "NA",
          averageViewsYT: artist.youtube?.averageViews || "NA",
          averageViewsIG: artist.instagram?.averageViews || "NA",
          commercialCreatorYT: artist.youtube?.commercial,
          commercialCreatorIGReel: artist.instagram?.reelCommercial,
          commercialCreatorIGStory: artist.instagram?.storyCommercial,
        });
        artist.categories.forEach((it) => tCategories.add(it));
        artist.languages.forEach((it) => tLanguages.add(it));
      });
      setData(tArtists);
      console.log({ tCategories, tLanguages });
      setCategories([...tCategories].filter((item) => item.length));
      setLanguages([...tLanguages].filter((item) => item.length));
    }
    // }
  }, [artists]);

  async function handleDeleteArtist(id) {
    console.log({ id });
    try {
      const res = await API_ARTIST().delete("/delete", {
        params: {
          id,
        },
      });
      console.log({ res });
      setData(data.filter((item) => item._id !== id));
      showAlert("success", "Deleted Artist Successfully");
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      console.log(error);
    }
  }

  return (
    <MainLayout
      navbarProps={{
        titleProps: {
          name: "Manage Artists",
          disabled: true,
          isBackIconVisible: true,
        },
      }}
    >
      <div className={styles.tablesContainer}>
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            <Tabs value={tab} onChange={handleTabsChange} aria-label="Tabs">
              <Tab
                icon={<TabIcon filled={tab === 0} value={0} />}
                // value={0}
                aria-label="Overview"
              />
              <Tab
                icon={<TabIcon filled={tab === 1} value={1} />}
                // value={1}
              />
              <Tab
                icon={<TabIcon filled={tab === 2} value={2} />}
                // value={2}
              />
            </Tabs>
            {/* <Button>Save</Button> */}
          </div>
          <div>
            <ExportTableButton
              dataSource={selectedRows}
              columns={selectArtistColumns.main}
              btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
              showColumnPicker
            >
              Export to CSV
            </ExportTableButton>
            <Button rightIcon={<Save />} onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>

        {/* </Box> */}
        {/* )} */}
        <TabPanel value={tab} index={0}>
          <div className={styles.tableContainer}>
            <CustomTable
              columns={[
                ...selectArtistColumns[
                  tab === 0 ? "main" : tab === 1 ? "info" : "contact"
                ],
                {
                  title: "Del Artist",
                  dataIndex: "delArtist",
                  key: "delArtist",
                  fixed: "right",
                  width: 100,
                  render: (text, record) => (
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => handleDeleteArtist(record._id)}
                    >
                      <IconButton>
                        <DeleteOutlined htmlColor="pink" />
                      </IconButton>
                    </Popconfirm>
                  ),
                },
              ]}
              data={data}
              isSelectable
              width={2200}
              setData={setData}
              setModifiedArtists={setModifiedArtists}
              onRowSelect={handleSelectRow}
              //   selectedRows={campaign?.selectedArtists || []}
            />
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className={styles.tableContainer}>
            <CustomTable
              columns={selectArtistColumns.info}
              data={data}
              isSelectable
              setData={setData}
              setModifiedArtists={setModifiedArtists}
              onRowSelect={handleSelectRow}
              categories={categories}
              languages={languages}
              //   selectedRows={campaign?.selectedArtists || []}
            />
          </div>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <div className={styles.tableContainer}>
            <CustomTable
              columns={selectArtistColumns.contact}
              data={data}
              isSelectable
              setData={setData}
              setModifiedArtists={setModifiedArtists}
              onRowSelect={handleSelectRow}
              //   selectedRows={campaign?.selectedArtists || []}
            />
          </div>
        </TabPanel>
      </div>
    </MainLayout>
  );
};

export default ManageArtists;
